export default {
	scan: {
		scantitle: "请上传您的图片。",
		upload: "上传",
		crop: "裁剪",
	},
	
    home: {
        parameterTitle: '产品参数表',
    	parameters: '产品参数',
		information: '详细信息',
    	qrcode: '二维码',
    	type: '类型',
    	standard: '规格',
    	bnumber: '批号',
    	inpack: '内包装',
    	outpack: '外包装',
    	time: '生产时间',
        company: '生产商',
        cname: '山东能特异能源科技有限公司',
    },
	bottom: {
		home: '主页',
		main: '产品简介',
		advan: '产品优势',
		pack: '产品包装',
	},
	main: {
		maintitle: '产品简介',
		shortintro1: '分子筛（又称合成沸石）是一种硅铝酸盐多微孔网状晶体，由SiO2 和Al2O3四面体组成，具有均一的孔径（约几埃）和极大的表面积。',
		shortintro2: '在分子筛晶格中存在金属阳离子（如Na+，K+，Ca2+ 等），以平衡四面体中多余的负电荷。',
		shortintro3: '不同类型的分子筛孔径或形状各不相同， 可以用来分离各种各样不同的分子。水是极性很强的分子，A型分子筛与水的亲和力极高，常常被用作效果极佳的干燥剂。',
		shortintro4: '我们这里讲的分子筛，是指只能用来吸收水分子的3A（1A=10-10米）型分子筛。',
		shortintro5: '它比硅胶干燥剂、氧化铝干燥剂及其它任何类型的粘土干燥剂在干燥能力与深度方面高出几十倍。',
		
		collaps1_title: '3A分子筛',
		collaps1_1: '3A型分子筛的晶格孔道只有3埃，是所有分子筛当中孔道最小的，只能吸收水分，不能吸收空气中任何其它成份，因为水分子的临界直径为2.8埃且极性很强。其它任何型号的分子筛都会吸附空气当中的氧气或氮气，而且这种吸附能力因为温度的微小变化而十分敏感。下表列出常见的四种分子筛相关数据，以作比较参考。',
		collaps1_out: '对比展示',
		collaps1t_to11_1: '分子筛',
		collaps1t_to11_2: '类型',
		collaps1t_to12_1: '孔径',
		collaps1t_to12_2: '(埃)',
		collaps1t_to13_1: '可吸',
		collaps1t_to13_2: '附物',
		collaps1t_to14_1: '非吸',
		collaps1t_to14_2: '附物',
		collaps1t_to24_1: '其他一切',
		collaps1t_to33_1: '氮气',
		collaps1t_to34_1: '氩气,',
		collaps1t_to34_2: '氪气,',
		collaps1t_to34_3: 'SF6溶质',
		collaps1t_to43_1: '大部分气体',
		collaps1t_to43_2: '小分子',
		collaps1t_to44_1: '大分子液体',
		collaps1t_to51_1: '硅胶或',
		collaps1t_to51_2: '氧化铝',
		collaps1t_to53_1: '所有',
		collaps1t_to54_1: '无',
		
		collaps2_title: '3A分子筛与中空玻璃干燥剂',
		collaps2_1: '鉴于上面的描述，当温度略微降低，其它型号的分子筛会大量的将中空玻璃内的空气吸附， 导致真空状态；当温度升高时，分子筛又把大量的空气释放到间隔层中，导致中空玻璃内空气膨胀。随着昼夜温差与季节更替的变换， 中空玻璃极易因膨胀和收缩而扭曲破碎，寿命缩短，玻璃的突然破碎会导致重大的安全事故，因此，空气干燥的中空玻璃严禁使用非3A型分子筛作为干燥剂。',
		collaps2_2: '3A分子筛作为中空玻璃专用干燥剂，最能符合中空玻璃所有性能指标及不对其物理和化学性质有任何影响。 它能连续深度吸附中空玻璃间隔层中的水和残留的有机物，有效预防玻璃的结露结霜， 彻底解决因中空玻璃膨胀或收缩而导致的扭曲破碎问题，保证中空玻璃最短20年-8℃的露点寿命。所以中空玻璃干燥剂只能选用3A分子筛。',
		collaps2_out: '细节展示',
		collaps2t_tw11: '项目',
		collaps2t_Q: '合格品',
		collaps2t_P: '优等品',
		collaps2t_tw21: '外观',
		collaps2t_tw22_1: '米白色、米黄色或土红色球形颗粒，',
		collaps2t_tw22_2: '无机械杂质',
		collaps2t_tw31: '松装堆积密度/(g/ml)',
		collaps2t_tw41: '静态水吸附',
		collaps2t_tw51: '粒度/%',
		collaps2t_tw61: '静态氮气吸附/(mg/g)',
		collaps2t_tw71: '包装品含水量/%',
		collaps2t_tw81: '吸水速率/%',
		collaps2t_tw91: '气体解吸量/(ml/g)',
		collaps2t_tw101: '温升/℃',
		collaps2t_tw111: '落粉量/(mg/g)',
		
		collaps3_title: '技术指标详解',
		collaps3_line1: '颗粒合格率(mm，%):颗粒的合格率范围应控制在98%以上。较大粒度的适合手工填充，较小粒度的适合机械填充。',
		collaps3_line2: '静态水吸附(g/g，%):在一定的相对温度和湿度下，静态水吸附量越大，分子筛质量越好；只有在相对湿度较低时，吸附量的大小才能显示出分子筛干燥能力的高低。',
		collaps3_line3: '抗压强度(N/颗):如果分子筛的抗压强度不符合要求，极易造成分子筛的破损，影响分子筛的使用寿命。',
		collaps3_line4: '堆积密度(g/ml):该参数指示分子筛的装填密度，仅对客户提供参考。',
		collaps3_line5: '落粉(unit):落粉越低，分子筛的粉尘就越少，进而更有利于保持中空玻璃的洁净透明，保持生产环境清洁卫生，有利于延长分子筛灌装机的寿命。',
		collaps3_line6: '包装含水量(%):包装含水量越小，表明分子筛在储存和运输过程中的预吸附就越少，剩余吸附量就越大，其露点控制能力就越强，分子筛的温升就越高，质量就越好。',
		collaps3_line7: '温升(℃):温升是指分子筛由于吸附水放出热量而导致本身温度的升高。因为分子筛对水的吸附过程是放热过程，所以分子筛的瞬间温升可作为判断分子筛快速吸水能力的依据之一。在同一测试方法下，瞬间温升越高说明分子筛的吸附水效能越强(分子筛含水低或吸附量大)。',
	},
	advan: {
		toptitle: '产品优势',
		shortIntro: '我们的产品有效吸附容量是粘土类中空干燥剂的20倍，我们的产品平均使用寿命是4A分子筛中空干燥剂的8倍。',
		line1: '低落粉---每10克落粉量低于12 unit。',
		line2: '高吸附--- 在露点-40℃时，仍能保持15%的吸附能力。',
		line3: '高温升---超过33℃，确保中空玻璃20年以上-8℃的露点寿命。',
		line4: '低含水--- 950℃的烧失量，含水小于2%。',
		line5: '均一粒度---粒度合格率98%以上。',
		
		specific: '具体优势',
		specificdetail: '具体说来，我们的分子筛的优势主要表现在:',
		sline1: '较高的温升值，本分子筛的温升超过50℃（25g分子筛/25ml水），保证了中空玻璃能持续10年以上的使用寿命。',
		sline2: '极低的落粉率，这是本分子筛的最大优势，它充分保证了玻璃本身及其加工环境的清洁。',
		sline3: '极低的包装水含量，严格的测试标准决定了其极低的含水量。保证了中空玻璃长期处于低露点状态（露点越低，干燥程度越高，中空玻璃的效果越好）。',
		sline4: '较高的水吸附能力，特别是在相对湿度较低的情况下，仍然保持强大的干燥能力，这一特征保证了中空玻璃较长的使用寿命。较高的温升值也能间接的反映出这一指标的情况。',
		sline5: '洁净无碎屑与粉尘，球面光滑破碎很少，这是本分子筛严格质量控制的结果。',
		sline6: '价格合理，让您在实惠中体验本分子筛的技术给您带来的方便与效益。',
	},
	pack: {
		packtitle: '产品包装',
		packintro: '我们可以提供多种包装。',
		packbox: '箱装',
		boxpop1: '-25公斤纸箱包装',
		boxpop2: '内衬铝箔袋，选用高强度纸板，抗压强度高，唛头设计精美。',
		packbag: '袋装',
		bagpop1: '-包装内衬铝箔袋',
		bagpop2: '多层复合铝膜纸，密封程度好，不透光，不透水。',
		bagpop3: '-集装袋包装',
		bagpop4: '我公司所选用的集装袋性能优良，防潮、防尘、牢固安全。',
		packdrum: '桶装',
		drumpop1: '-140公斤纸桶包装',
		drumpop2: '选用高强度牛皮纸板，良好的密封性能，保证了远距离运输过程中产品的质量。',
		drumpop3: '-150公斤铁桶包装',
		drumpop4: '材质选用优质镀锌钢板，每个铁桶都经过严格的性能检测。',
		conpack: '集装箱容量',
		compop1: '-20\'GP集装箱',
		compop2: '20\'GP集装箱可以装600纸箱，净重15000公斤；或80纸桶，净重11200公斤；或80铁桶，净重12000公斤。',
		compop3: '-40\'GP集装箱',
		compop4: '40\'GP 集装箱可以装1000纸箱，净重25000公斤；或160纸桶，净重22400公斤；或160铁桶，净重24000公斤。',
		conclusion: '我们也可以提供定制化的包装。',
	},
}