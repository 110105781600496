<template>
<div class="ConRootClass">
<div class="background">
	<img :src="imgSrc" width="100%" height="100%" alt="" />
</div>
<div class="RootClass">
  <div style="height: 10rem;"></div>
  <div class=:TopTitle>
	  <p style="font-size: 150%; margin: 1rem 1rem 1rem 1rem;color: floralwhite" id="hometitle"><strong>{{ this.msg }}</strong></p>
  </div>
  <div class="btnchangeLang">
  <van-button round color="#FF0000" plain size="mini" @click="changeCN">中文版</van-button>
  <van-button round color="#0000FF" plain size="mini"  @click="changeEN">English</van-button>
  </div>
  <div style="height: 1rem;">
  </div>
  <div clss= "TableShow">
  	<table class="blog-post-table" border="0px solid #000;" cellspacing="2" style="margin: 2rem auto;width: 18rem;font-size: 100%">
  	    <tr>
  	        <td id="details" style="font-weight: 600;" align="center" colspan="2" bgcolor="beige">{{ $t("home.parameterTitle")}}</td>
  	    </tr>
		<tr bgcolor="antiquewhite">
			<td id="params" align="center">{{$t("home.parameters")}}</td>
			<td id="information" align="center">{{$t("home.information")}}</td>
		</tr>
		<tr bgcolor="beige">
			<td id="qrcode" align="center"> {{$t("home.qrcode")}} </td>
			<td align="center"> {{ tableData.QRInfo }} </td>
		</tr>
		<tr bgcolor="antiquewhite">
			<td id="standard" align="center" > {{$t("home.standard")}} </td>
			<td align="center"> {{ tableData.Standard }} </td>
		</tr>
		<tr bgcolor="beige">
			<td id="batch" align="center" >{{$t("home.bnumber")}}</td>
			<td align="center"> {{ tableData.BatchNum }} </td>
		</tr>
		<tr bgcolor="antiquewhite">
			<td id="inner" align="center" > {{$t("home.inpack")}} </td>
			<td align="center"> {{ tableData.InPack }} </td>
		</tr>
		<tr bgcolor="beige">
			<td id="ptime" align="center"> {{$t("home.time")}} </td>
			<td id="rtime" align="center"> {{ tableData.QInTime }} </td>
		</tr>
        <tr bgcolor="antiquewhite">
            <td id="pcompany" align="center"> {{$t("home.company")}} </td>
            <td id="pcompanyname" align="center"> {{$t("home.cname")}} </td>
        </tr>
	</table>
  </div>
  <div class="swapImg">
  	<van-swipe :autoplay="3000" >
  	  <van-swipe-item v-for="(image, index) in imageList" :key="index">
  	    <img style="width: 80%;" v-lazy="image" />
  	  </van-swipe-item>
  	</van-swipe>
   </div>	
  <div style="height: 5rem;"></div>
  </div>
  <!-- <div class= "BottomGuide">
  	<router-view />
  	<van-tabbar route active-color="#00cc00">
  	  <van-tabbar-item replace to="/home" icon="home-o">{{$t("bottom.home")}}</van-tabbar-item>
  	  <van-tabbar-item replace to="/main" icon="star-o">{{$t("bottom.main")}}</van-tabbar-item>
  	  <van-tabbar-item replace to="/advan" icon="fire-o" name="advan-q">{{$t("bottom.advan")}}</van-tabbar-item>
  	  <van-tabbar-item replace to="/pack" icon="bag-o" name="package-q">{{$t("bottom.pack")}}</van-tabbar-item>
  	</van-tabbar>
  </div> -->
  </div>
  
</template>

<script>
// @ is an alias to /src
export default {
	data() {
	    return {
	      active: "home-q",
		  imgSrc:require('/src/assets/backgnd.jpg'),
		  imageList: [
			  require('/src/assets/comshow_one.jpg'),
			  require('/src/assets/comshow_two.jpg'),
			  require('/src/assets/comshow_three.jpg'),
		  ],
		  tableData:[]
	    };
	  },
	created() {
	    this.showBooks()
	    },
	methods: {
	  showBooks(){
          this.msg = this.$route.params.msg
          console.log(this.msg)
          var flagR = this.$route.params.flag
          sessionStorage.setItem('flag', flagR)
          this.tableData = this.$route.params
          if (this.$i18n.locale === 'zh'){
              if (flagR === 'True'){
                  this.msg = '感谢您的使用!您选用的为正品!';
              }else if (flagR === 'False'){
                  this.msg = '抱歉我们未查到该商品信息!如有误,请联系我们.';
              }else{
                  this.msg = '服务器开小差了，请稍后重试!';
              }
          }else{
              if (flagR === 'True'){
                  this.msg = 'Congratulation!It\'s Genie!';
              }else if (flagR === 'False'){
                  this.msg = 'Sorry!It may not be our product.If wrong,please contact us.';
              }else{
                  this.msg = 'Sorry!Something wrong Happened!Try again later.';
              }
          }
	  },
	  changeCN(){
		  this.$i18n.locale = "zh"
		  var flagN = sessionStorage.getItem('flag')
		  if (flagN === 'True'){
			  this.msg = '感谢您的使用!您选用的为正品!';
		  }else if (flagN === 'False'){
			  this.msg = '抱歉我们未查到该商品信息!如有误,请联系我们.';
		  }else{
			  this.msg = '服务器开小差了，请稍后重试!';
		  }
	  },
	  changeEN(){
	  	  this.$i18n.locale = "en"
		  var flagN = sessionStorage.getItem('flag')
		  if (flagN === 'True'){
			  this.msg = 'Congratulation!It\'s Genie!';
		  }else if (flagN === 'False'){
			  this.msg = 'Sorry!It may not be our product.If wrong,please contact us.';
		  }else{
			  this.msg= 'Sorry!Something wrong Happened!Try again later.';
		  }
	  }
	}
};
</script>

<style>
  .background{
      width:100%;  
      height:100%;  /**宽高100%是为了图片铺满屏幕 */
      z-index:-1;
      position: absolute;
  }
   
  .RootClass{
      z-index:1;
      position: relative;
      /* left: 50%; */
      /* margin-top:30%; */
      /* transform: translate(-50%,-50%); */
  }
</style>
