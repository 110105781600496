export default {
	scan: {
		scantitle: "Please upload your photo.",
		upload: "Upload",
		crop: "Crop",
	},
	
    home: {
        parameterTitle: 'Product Parameters',
		parameters: 'Parameters',
		information: 'Information',
		qrcode: 'QRCode',
		type: 'Type',
		standard: 'Standard',
		bnumber: 'Batch',
		inpack: 'Inner Package',
		outpack: 'Outer Package',
		time: 'Time',
        company: 'Company',
        cname: 'ShanDong Natergy',
    },
	bottom: {
		home: 'Home',
		main: 'Main',
		advan: 'Advantages',
		pack: 'Packages',
	},
	main: {
		maintitle: 'Main Products',
		shortintro1: 'Molecular sieve (also called as synthesized zeolite) is a kind of silica-aluminates with micro-porous crystal.  It is a frame-work which is constructed by SiO2 and Al2O3. ',
		shortintro2: 'Molecular sieve has very similar aperture value (only several A) and large surface area. ',
		shortintro3: 'There are relevant positive ions in its lattice to balance the negative ions in the crystal frame.  Different molecular sieves have different shapes and different aperture values. ',
		shortintro4: 'We could separate different kinds of molecular by selectively using different molecular sieves.  Water is a sort of polar molecule, molecular sieve type A has dramatic affinity for water.  For the above reasons, molecular sieve is considered as the most ideal desiccant.  The molecular sieve here mentioned is type 3A which is only for adsorbing water. ',
		shortintro5: 'The water adoption depth of molecular sieve 3A is above several dozen times higher than other any other applicable desiccants.',
		
		collaps1_title: 'Molecular sieve 3A',
		collaps1_1: 'Most molecular sieves have been affinity to water,such as other molecular sieve 4A, 5A, 13X, type Y and so on.  They all also adsorb other molecules from air.  The pore of molecular sieve 3A is 3 angstrom, which can only adsorbs molecule smaller than this size, water molecule is just meet this size, so molecular sieve 3A can only adsorb water other than Nitrogen and Oxygen in air.  The following form lists 4 types of common desiccants often used in IG and their aperture values.  This form also shows which kinds of molecules are likely to be absorbed by these desiccants.',
		collaps1_out: 'Show Comparation',
		collaps1t_to11_1: 'Desiccant',
		collaps1t_to11_2: 'Type',
		collaps1t_to12_1: 'Pore Size',
		collaps1t_to12_2: '(angstrom)',
		collaps1t_to13_1: 'Absorb',
		collaps1t_to13_2: 'Substances<',
		collaps1t_to14_1: 'Repel',
		collaps1t_to14_2: 'substances',
		collaps1t_to24_1: 'All others',
		collaps1t_to33_1: 'Nitrogen',
		collaps1t_to34_1: 'Ar,',
		collaps1t_to34_2: 'Kr,',
		collaps1t_to34_3: 'Large molecule \ngases',
		collaps1t_to43_1: 'Most Gases,',
		collaps1t_to43_2: 'Small Molecule Liquids',
		collaps1t_to44_1: 'Large Molecule \n Liquids',
		collaps1t_to51_1: 'Silica gel or',
		collaps1t_to51_2: 'Alumina',
		collaps1t_to53_1: 'All',
		collaps1t_to54_1: 'None',
		
		collaps2_title: 'Molecular sieve 3A and insulating glass desiccant',
		collaps2_1: 'If use other molecular sieve as desiccant, these molecular sieves can also adsorb nitrogen or oxygen in air at lower temperature, and could release air at higher temperature, the inflated and shrinking air could cause window to be distorted with the temperature changing at day-night or seasons changing. Up to now, molecular sieve 3A is the unique proper desiccant which is suitable for insulating glass.',
		collaps2_2: '3A insulating glass (IG) desiccant is made from molecular sieve 3A. This IG desiccant can deeply co-adsorb resident moisture and organics in insulating glass, keep the IG clear and transparent even at very low outside temperature, prevent the condense of inside vapor. More importantly, it can resolve the problem of window distortion caused by IG inflation and shrinking under season or day-night temperature changing; this would prolong the life span of IG windows considerably.',
		collaps2_out: 'Show Details',
		collaps2t_tw11: 'Properties',
		collaps2t_Q: 'Q',
		collaps2t_P: 'P',
		collaps2t_tw21: 'Outlook',
		collaps2t_tw22: 'Beige white, beige or earthy red spherical particles,\n no mechanical impurities',
		collaps2t_tw31: 'Loose packing density/(g/ml)',
		collaps2t_tw41: 'Static Adsorbed Water',
		collaps2t_tw51: 'Particle size/%',
		collaps2t_tw61: 'Static Adsorbed Nitrogen/(mg/g)',
		collaps2t_tw71: 'Moisture content of package/%',
		collaps2t_tw81: 'Water Absorption Rate/%',
		collaps2t_tw91: 'Gas Desorption Capacity/(ml/g)',
		collaps2t_tw101: 'Temperature Rise/℃',
		collaps2t_tw111: 'Falling Powder Amount/(mg/g)',
		
		collaps3_title: 'Technical parameter',
		collaps3_line1: 'Size ratio up to grade（mm,% ): Size ratio is controlled up to 98%, higher value means better screen distribute. Bigger size molecular sieve is suitable for manual filling and the smaller one is suitable for automatic machine filling.',
		collaps3_line2: 'Moisture adsorption capacity (MAC)（mg/g,% ): The MAC is positively correlated to the quality of molecular sieve at a certain relative humidity and temperature. The prerequisites here are relative humidity and temperature which are very important for testing MAC. Only higher MAC at low relative humidity can demonstrate the drying ability of molecular sieve, this parameter affects delta T value.',
		collaps3_line3: 'Compression strength (N/Unit): If the compression strength of molecular sieve is too low, the molecular sieve will be crushed or powered at the transportation or be filled in the IG spacers, which would cause serious quality problem.',
		collaps3_line4: 'Bulk density (g/ml): This parameter is only for customer reference.',
		collaps3_line5: 'Dust drops (unit): this parameter is much sensitive for customer; the important thing is to unify the testing method. Sometimes low dust drops value can not tell low dust drops, because testing method is different. Less dust drops means more clean of molecule sieve. It is very important to keep IG clean after installation and protect the auto filling machine.',
		collaps3_line6: 'RMC (%): Also called resident moisture content. The less RMC in molecular sieve means the better dew point control. Less RMC level indicates better dew point level, and also higher delta T value.',
		collaps3_line7: 'Delta T value (℃): The molecular sieve would release heats when it absorbs water. This parameter shows how much temperature would increase, when certain molecular sieve adsorbs water under certain condition. As the process of water adsorption is a heat releasing process, the momentary delta T value can be used as a method to judge adsorption rate of molecular sieve. At the same test method, the higher the momentary delta T value is, the stronger the water adsorption efficiency is. The higher delta T value can guarantee the ideal dew point of the IG.',
	},
	advan: {
		toptitle: 'Advantages',
		shortIntro: 'The effective adsorption volume is  than that of clay desiccant. The average lifespan of our product is eight times than that of molecular sieve 4A.',
		line1: 'Low dust level: below 50 NTU/10 gram.',
		line2: 'High adsorption capacity: maintain 15% effective adsorption capacity at dew point of -40℃.',
		line3: 'High Delta T value: more than 30℃, guarantee the dew point of I.G. at -8℃ for over 20 years.',
		line4: 'Low moisture residence: less than 1.7% at 540℃.',
		line5: 'Uniform granulation: size ratio reaches above 98%.',
		
		specific: 'Specific advantages',
		specificdetail: 'Specifically, the main advantages of green energy molecular sieve are as follow:',
		sline1: 'High delta T value, our sieve has the delta T value of over 30 ℃ (10g sieves/10ml H2O); it gives insulating glass deep dehydrated air for a long-lasted working span.',
		sline2: 'Extremely low dust level is the primarily advantage of our sieve, our sieve are reputed for its stable low dust level, this gives glass guaranteed clean process both for glass unit and the working surroundings.',
		sline3: 'Low moisture residence, moisture residence tells how powerful and effective the sieve would be: the lower moisture residence give, the lower of dew point of windows reaches.',
		sline4: 'Higher water adsorption capacity, this character tells how long the drying protection given by desiccant last, this character is also reflected in the delta T value level.',
		sline5: 'Clean beads, our sieve is clean, it is almost impossible to find out crushed beads, chippings in the packing bottom of bag or drum. This is the result of performance our strict QCS in producing process.',
		sline6: 'Our sieve is cheap, we specialize in producing molecular sieve, knowing how technology not only guarantees the quality, but also cuts down the cost of producing. To buy our sieve, is to enjoy and benefit from the advantages of our technology.',
	},
	pack: {
		packtitle: 'Packages',
		packintro: 'We could offer several kinds of packages.',
		packbox: 'Box',
		boxpop1: '-25kg carton box',
		boxpop2: 'Lined with aluminum foil bag.High compression strength and perfect marks printing.',
		packbag: 'Bag',
		bagpop1: '-Aluminum foil bag',
		bagpop2: 'Composed of several layer aluminum foils.',
		bagpop3: '-Big bag',
		bagpop4: 'We can offer all sizes of big bags.Tightknit and convenient.',
		packdrum: 'Drum',
		drumpop1: '-140kg fiber',
		drumpop2: 'It is made of brown fiber paper.Very strong and environmental.',
		drumpop3: '-150kg steel drum',
		drumpop4: 'Every drum has passed the test for air tightness.',
		conpack: 'Container Capacity',
		compop1: '-20\'GP container',
		compop2: '20\'GP container can be loaded: 600 cartons, net weight 15000kg or 80 fiber drums, net weight 11200kg; or 80 steel drums, net weight 12000kg.',
		compop3: '-40\'GP container',
		compop4: '40\'GP container can be loaded: 1000 cartons, net weight 25000kg; or 160 fiber drums, net weight 22400kg or 160 steel drums,net weight.',
		conclusion: 'We could also design different kinds of packages according to requirements of customers.',
	},
}