import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
	path: "/newup",
	name: "newUp",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newUP.vue"),
  },
  {
    path: "/",
    name: "qrscan",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Qrscan.vue"),
  },
  {
    path: "/main",
    name: "Main",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Main.vue"),
  },
  {
	  path: "/advan",
	  name: "Advan",
	  component: () =>
	    import(/* webpackChunkName: "about" */ "../views/Advantages.vue"),
	  
  },
  {
  	  path: "/pack",
  	  name: "Pack",
  	  component: () =>
  	    import(/* webpackChunkName: "about" */ "../views/Packages.vue"),
  	  
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
